body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Circular Std Book'; /* Replace with the actual name of your font */
  src: url('./assets/fonts/circular-std/CircularStd-Book.woff2') format('woff2'),  /* Adjust paths and formats as needed */
  url('./assets/fonts/circular-std/CircularStd-Book.woff') format('woff'),
  url('./assets/fonts/circular-std/CircularStd-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'CircularStd-Black', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.resourcesTitle div div span{
  /*font-size: 20px !important;*/
}

.hide-clear-indicator .MuiAutocomplete-clearIndicator,
.hide-clear-indicator .MuiAutocomplete-clearIndicator:hover {
  display: none;
}

/*@keyframes slide-out {*/
/*  from {*/
/*    opacity: 1;*/
/*    transform: translateX(0);*/
/*  }*/
/*  to {*/
/*    opacity: 0;*/
/*    transform: translateX(-100%);*/
/*  }*/
/*}*/
